import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';

new Vue({
  render: h => h(App),
}).$mount('#app')

const script = document.createElement('script');
script.async = true;
script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16770213917';

script.onload = () => {
  console.log('gtag.js loaded successfully!');
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  window.gtag = gtag; // 全局定义 gtag 方法
  gtag('js', new Date());
  gtag('config', 'AW-16770213917');
};

script.onerror = () => {
  console.error('Failed to load gtag.js script');
};

document.head.appendChild(script);
